var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannerImage",style:({
        'background-image':
          'url(' + require('../assets/img/banner/navi.jpg') + ')',
      })},[_vm._m(0)])]),_c('div',{staticClass:"cardList mt-4 mt-md-5 mt-lg-5 pt-lg-3"},[_c('div',{staticClass:"containerLarge"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"},[_c('div',{staticClass:"cardImage",on:{"click":function($event){return _vm.toGlobal('/products', 'marine')}}},[_c('div',{staticClass:"cardBox",style:({
                'background-image':
                  'url(' +
                  require('../assets/img/products/prodotti.jpg') +
                  ')',
              })}),_vm._m(2)])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"},[_c('div',{staticClass:"cardImage",on:{"click":function($event){return _vm.toGlobal('/size-your-system', 'marine')}}},[_c('div',{staticClass:"cardBox",style:({
                'background-image':
                  'url(' +
                  require('../assets/img/banner/size-your-system.jpg') +
                  ')',
              })}),_vm._m(3)])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"},[_c('div',{staticClass:"cardImage",on:{"click":function($event){return _vm.toGlobal('/marine-contacts', 'marine')}}},[_c('div',{staticClass:"cardBox",style:({
                'background-image':
                  'url(' + require('../assets/img/banner/contacts.jpg') + ')',
              })}),_vm._m(4)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"containerLarge p-4 p-sm-3 p-md-3 p-lg-4 p-xl-4"},[_c('div',{staticClass:"bannerImage-card p-2 p-sm-3 p-md-3 p-lg-4 p-xl-5 text-center col-md-8 offset-md-2"},[_c('h1',[_vm._v("Marine")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mb-3 mb-md-5"},[_c('p',{staticClass:"lead text-center"},[_vm._v(" Solutions for lifetime vessel performance, built on systems, knowledge and services in key applications from bow to stern ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardGrandient"}),_c('div',{staticClass:"cardContent"},[_c('h2',{staticClass:"cardContent-title"},[_vm._v("Products")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardGrandient"}),_c('div',{staticClass:"cardContent"},[_c('h2',{staticClass:"cardContent-title"},[_vm._v("Size your system")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardContainer"},[_c('div',{staticClass:"cardGrandient"}),_c('div',{staticClass:"cardContent"},[_c('h2',{staticClass:"cardContent-title"},[_vm._v("Contacts")])])])
}]

export { render, staticRenderFns }